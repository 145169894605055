// File: src/utils/fileTypeDetector.js

export async function detectFileType(url) {
  // List of common file extensions and their corresponding types
  const fileTypes = {
    pdf: ['pdf'],
    word: ['doc', 'docx'],
    excel: ['xls', 'xlsx', 'csv'],
    powerpoint: ['ppt', 'pptx'],
    image: ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'],
  };

  // Function to check file extension
  function checkExtension(url) {
    const extension = url.split('.').pop().toLowerCase();
    for (const [type, extensions] of Object.entries(fileTypes)) {
      if (extensions.includes(extension)) {
        return type;
      }
    }
    return 'other';
  }

  // Function to check Content-Type header
  async function checkContentType(url) {
    try {
      const response = await fetch(url, { method: 'HEAD' });
      const contentType = response.headers.get('Content-Type');
      
      if (contentType.includes('pdf')) return 'pdf';
      if (contentType.includes('word')) return 'word';
      if (contentType.includes('excel') || contentType.includes('spreadsheet')) return 'excel';
      if (contentType.includes('powerpoint') || contentType.includes('presentation')) return 'powerpoint';
      if (contentType.includes('image')) return 'image';
      
      return 'other';
    } catch (error) {
      console.error('Error fetching Content-Type:', error);
      return null;
    }
  }

  // Main detection logic
  let fileType = checkExtension(url);
  
  // If extension check fails or returns 'other', try Content-Type
  if (fileType === 'other') {
    const contentTypeResult = await checkContentType(url);
    if (contentTypeResult) {
      fileType = contentTypeResult;
    }
  }
  
  return fileType;
}