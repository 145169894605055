<template>
    <div class="container-fluid">
      <div class="row">
        <!-- Sidebar -->
        <nav id="sidebar" class="col-md-3 col-lg-2 d-md-block bg-light sidebar p-0">
          <div class="position-sticky">
            <ul class="nav flex-column w-100">
              <li class="nav-item w-100" v-for="item in sidebarItems" :key="item.component">
                <a 
                  class="nav-link w-100 px-3 py-2" 
                  href="#" 
                  @click.prevent="setActiveView(item)"
                  :class="{ 'active': currentView === item.component }"
                >
                  {{ item.text }}
                </a>
              </li>
            </ul>
          </div>
        </nav>
  
        <!-- Main content -->
        <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <component :is="currentView"></component>
        </main>
      </div>
    </div>
  </template>
  
  <script setup>
  import { shallowRef } from 'vue'
  import Dashboard from '@/views/adviser portal views/Dashboard.vue'
  import Orders from '@/views/adviser portal views/Orders.vue'
  
  const currentView = shallowRef(Dashboard)
  
  const sidebarItems = [
    { text: 'Dashboard', component: Dashboard },
    { text: 'Orders', component: Orders },
  ]
  
  const setActiveView = (item) => {
    currentView.value = item.component
  }
  </script>
  
  <style scoped>
  .sidebar {
    min-height: 100vh;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  }
  
  .sidebar .nav-link {
    font-weight: 500;
    color: #333;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .sidebar .nav-link:hover {
    background-color: #e9ecef;
  }
  
  .sidebar .nav-link.active {
    color: #007bff;
    background-color: #e9ecef;
  }
  
  /* Ensure the sidebar takes full width on smaller screens */
  @media (max-width: 767.98px) {
    .sidebar {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: 100;
      padding: 48px 0 0;
      box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
    }
  }
  </style>