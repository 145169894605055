<template>
  <div class="option-quotes">
      <h1>Option Quotes</h1>
      <div class="d-flex">
      <AutoComplete 
        v-model="clientName" 
        placeholder="Client Name" 
        :formatFunc="inputMaskingConfigs.clientName.format" 
        class="m-2"
      />
      <AutoComplete 
        v-model="clientEmail" 
        placeholder="Client Email Address" 
        :formatFunc="inputMaskingConfigs.clientEmail.format" 
        class="m-2"
      />
      <AutoComplete 
        v-model="powerwrapId" 
        placeholder="Powerwrap ID" 
        :formatFunc="inputMaskingConfigs.powerwrapId.format" 
        class="m-2"
      />
      </div>
      <div class="d-flex">
      <AutoComplete
        v-model="selectedTicker"
        :items="tickers"
        placeholder="Select a ticker"
        @select="onTickerSelect"
        class="m-2"
      />
      <AutoComplete
        v-model="selectedTenor"
        :items="tenors"
        placeholder="Select a tenor"
        :disabled="!selectedTicker"
        @select="onTenorSelect"
        class="m-2"
      />
      <AutoComplete 
        v-model="investmentFaceValue" 
        placeholder="Investment Face Value" 
        class="m-2"
        :formatFunc="inputMaskingConfigs.investmentFaceValue.format" 
      />
      </div>
    <table v-if="optionData.length" id="optionTable">
      <thead>
        <tr>
          <th>Strike Price</th>
          <th>Put Price</th>
          <th>Call Price</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in optionData" :key="index" 
            @click="selectRow(row)" 
            :class="{ 'selected-row': selectedRow === row }">
          <td>{{ row['Strike Price'] }}</td>
          <td>{{ row['Put'] }}</td>
          <td>{{ row['Call'] }}</td>
        </tr>
      </tbody>
    </table>
    <button v-if="selectedRow" @click="submitData" class="btn btn-primary">Submit</button>
    <div id="status">{{ statusMessage }}</div>
    <a v-if="investAcknowledgementLink" :href="investAcknowledgementLink" target="_blank">Investor Acknowledgement Link</a>
  </div>
</template>


<script setup>
import { ref, computed, onMounted } from 'vue';
import api from '@/api';
import AutoComplete from '@/components/AutoComplete.vue';

const clientName = ref('');
const clientEmail = ref('');
const powerwrapId = ref('');
const selectedTicker = ref('');
const selectedTenor = ref('');
const investmentFaceValue = ref('');
const optionData = ref([]);
const selectedRow = ref(null);
const statusMessage = ref('');
const investAcknowledgementLink = ref('');

const ticker_tenor_dict = ref({});
const tickers = computed(() => Object.keys(ticker_tenor_dict.value));
const tenors = computed(() => ticker_tenor_dict.value[selectedTicker.value] || []);

onMounted(async () => {
  try {
    const response = await api.get("/option/tickers-and-tenors");
    ticker_tenor_dict.value = response.data;
  } catch (error) {
    console.error('Failed to fetch tickers and tenors:', error);
  }
});

const getOptionPricing = async (ticker, tenor) => {
  try {
    const response = await api.get('/option/option-pricing', {
      params: { ticker, tenor, strike_order_by: 'asc' }
    });
    optionData.value = response.data;
  } catch (error) {
    console.error('Failed to fetch option pricing:', error);
  }
};

const selectRow = (row) => {
  selectedRow.value = row;
};

const submitData = async () => {
  if (!selectedRow.value) return;

  const submitData = {
    client_name: clientName.value,
    powerwrap_id: powerwrapId.value,
    client_email: clientEmail.value,
    investment_face_value: investmentFaceValue.value,
    ticker: selectedTicker.value,
    tenor: selectedTenor.value,
    strike_price: selectedRow.value['Strike Price'],
    put_price: selectedRow.value['Put'],
    call_price: selectedRow.value['Call']
  };

  statusMessage.value = 'Generating document...';

  try {
    const response = await api.post('/option/create-dpa', submitData);
    if (response.data && response.data.investment_acknowledgement_link) {
      investAcknowledgementLink.value = response.data.investment_acknowledgement_link;
      statusMessage.value = 'Document generated successfully!';
    } else {
      statusMessage.value = 'Failed to generate document. Please try again.';
    }
  } catch (error) {
    console.error('Generation failed:', error);
    statusMessage.value = 'Failed to generate document. Please try again.';
  }
};

const onTickerSelect = () => {
  selectedTenor.value = '';
  optionData.value = [];
};

const onTenorSelect = () => {
  if (selectedTicker.value && selectedTenor.value) {
    getOptionPricing(selectedTicker.value, selectedTenor.value);
  }
};


const inputMaskingConfigs = {
  investmentFaceValue: {
    format: (value) => {
      value = value.replace(/[^\d.]/g, '');
      value = value.replace(/^0+/, '');
      if (value.includes('.')) {
        value = value.replace(/\.(?=.*\.)/g, '');
      }
      const parts = value.split('.');
      if (parts.length > 1) {
        value = `${parts[0]}.${parts[1].slice(0, 2)}`;
      }
      const [integerPart, decimalPart] = value.split('.');
      const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return (decimalPart || value.includes('.')) ? `${integerWithCommas}.${decimalPart || ''}` : integerWithCommas;
    }
  },
  clientName: {
    format: (value) => {
      value = value.replace(/[^A-Za-z\s]/g, '');
      // console.log(value);
      return value;
    }
  },
  powerwrapId: {
    format: (value) => value.replace(/[^\d]/g, '').slice(0, 9)
  },
  clientEmail: {
    format: (value) => {
      value = value.replace(/[^a-zA-Z0-9@._-]/g, '');
      if (value.split('@').length > 2) {
        const parts = value.split('@');
        value = parts.shift() + '@' + parts.join('');
      }
      return value;
    }
  }
};

</script>

<style scoped>
@import '@/styles/main.css'; 

.option-quotes {
  font-family: Arial, sans-serif;
  margin: 20px;
}

.autocomplete {
  width: 200px;
  height: 30px;
}

table {
  margin-top: 20px;
  width: 100%;
  border-collapse: collapse;
}
th, td {
  border: 1px solid black;
  text-align: center;
  padding: 5px;
}
tr:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
.selected-row {
  background-color: #e0e0e0;
}
button {
  margin-top: 10px;
  padding: 10px;
}
#status {
  margin-top: 10px;
  font-weight: bold;
}
a {
  display: block;
  margin-top: 10px;
}
</style>