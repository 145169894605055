<template>
  <div class="investor-acknowledgement mt-5">
    <div class="d-flex justify-content-center">
    </div>
    <h1 class="text-center mt-3">Investment Documents</h1>

    
    <div class='w-100 px-5 my-4' v-if="!isInvestorAcknowledgementSigned">
      <div class="tabs d-flex justify-content-center mb-3">
        <button 
          @click="activeTab = 'termSheet'" 
          :class="['tab-button', { active: activeTab === 'termSheet' }]"
        >
          Term Sheet
        </button>
        <button 
          @click="activeTab = 'investorAcknowledgement'" 
          :class="['tab-button', { active: activeTab === 'investorAcknowledgement' }]"
        >
          Investor Acknowledgement
        </button>
      </div>
      <div id="pdfWindow" class="d-flex justify-content-center" ref="pdfContainer">
        <VuePdfEmbed
          v-show="activeTab === 'termSheet'"
          :source="termSheet"
          :width="pdfWidth"
          :key="'termSheet'"
        />
        <VuePdfEmbed
          v-show="activeTab === 'investorAcknowledgement'"
          :source="investorAcknowledgement"
          :width="pdfWidth"
          :key="'investorAcknowledgement'"
        />
      </div>
      <div class="mt-5 mb-3 px-4">
        <h5>I hereby acknowledge and accept the terms and conditions associated with this investment, and I commit to fulfilling my obligations as outlined in the accompanying documentation.</h5>
        
        <div v-if="signatureOne" class="d-flex align-items-end">
          <h5>Signature:</h5>
          <img :src="signatureOne" class="signature-img" alt="Signature" />
        </div>
      </div>
    </div>

    <div class='w-100 px-5 my-4' v-else>
      <h4 class="alert-heading">Investor Acknowledgement Signed!</h4>
      <div id="pdfWindow" class="d-flex justify-content-center" ref="pdfContainer">
        <VuePdfEmbed
          v-if="signedInvestorAcknowledgement"
          :source="signedInvestorAcknowledgement"
          :width="pdfWidth"
        />
      </div>  
    </div>
  </div>
  
  <div class="mb-5 d-flex justify-content-center" v-if="!isInvestorAcknowledgementSigned">
    <SignatureBox class="mx-1" @signature-saved="handleSavedSignature"/>
    <button 
      class="mx-1 btn btn-lg" 
      :class="{'disabled btn-secondary' : isConfirmButtonDisabled, 'btn-success':!isConfirmButtonDisabled}" 
      ref="confirmButtonRef"
      @click="confirmInvestment"
    >Confirm</button>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import VuePdfEmbed from 'vue-pdf-embed'
import 'vue-pdf-embed/dist/style/index.css'
import SignatureBox from '@/components/SignatureBox.vue'
import { baseURL, encodeURLWithParams, default as api } from '@/api'
import { investmentDocumentFileType } from '@/enums'

const props = defineProps({
  id: String
});


const termSheet = ref("")
const investorAcknowledgement = ref("")
const activeTab = ref('termSheet')
const isConfirmButtonDisabled = ref(true)
const confirmButtonRef = ref(null)
const isInvestorAcknowledgementSigned = ref(false)
const signedInvestorAcknowledgement = ref(null)

const pdfContainer = ref(null)
const pdfWidth = ref(1000) // default width

const signatureOne = ref(null)

const updatePdfWidth = () => {
  if (pdfContainer.value) {
    pdfWidth.value = pdfContainer.value.clientWidth
  }
}

const handleSavedSignature = (signatureData) => {
  signatureOne.value = signatureData
  if (signatureData) {
    isConfirmButtonDisabled.value = false
    confirmButtonRef.value.focus()
  } else {
    isConfirmButtonDisabled.value = true
  }
}

const confirmInvestment = async () => {
  const data = {
    "order_id": props.id,
    "signature": signatureOne.value
  }
  try {
    const response = await api.post("/order/sign-investor-acknowledgement", data)
    isInvestorAcknowledgementSigned.value = true
  } catch (error) {
    alert("Failed to submit Investor Acknowledgement")
    console.log(error)
  }

  const params = {
    "order_id": props.id,
    "file_type": investmentDocumentFileType.singedInvestorAcknowledgement
  }
  try{
    api.get("/files/order-file", { params })
    .then(response => {
      signedInvestorAcknowledgement.value = response.data.url
    })
  } catch (error) {
    alert("Failed to submit Investor Acknowledgement")
    console.log(error)
  }

}

onMounted(() => {
  updatePdfWidth()
  window.addEventListener('resize', updatePdfWidth)
  
  console.log("mounted")

  // Load Term Sheet
  const params = {
    "order_id": props.id,
    "file_type": investmentDocumentFileType.finalTermSheet
  }
    api.get("/files/order-file", { params })
    .then(response => {
      termSheet.value = response.data.url
    })
    .catch(error => {
      console.log(error)
    })

    api.get("/files/investor-acknowledgement-template")
    .then(response => {
      console.log(response.data.url)
      investorAcknowledgement.value = response.data.url
    })
    .catch(error => {
      console.log(error)
    })  
})

onUnmounted(() => {
  window.removeEventListener('resize', updatePdfWidth)
})
</script>

<style scoped>
#pdfWindow {
  overflow-y: auto;
  height: 60vh;
  width: 100%;
  border: 2px solid #ccc;
  box-sizing: border-box;
}

.tabs {
  gap: 10px;
}

.tab-button {
  padding: 10px 20px;
  border: 1px solid #ccc;
  background-color: #f8f9fa;
  cursor: pointer;
  transition: background-color 0.3s;
}

.tab-button.active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.signature-img {
  height: 100px;  /* or any specific height */
  width: auto;
  display: block;
  border-bottom: 2px solid #000;
}
</style>