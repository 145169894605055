import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/views/HomePage.vue'
import OptionQuotes from '@/views/OptionQuotes.vue'
import InvestorAcknowledgement from '@/views/InvestorAcknowledgement.vue'
import AuthView from '@/views/AuthView.vue'
import AdviserPortal from '@/views/AdviserPortal.vue'
import AdminPortal from '@/views/AdminPortal.vue'

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/option-quotes',
    name: 'OptionQuotes',
    component: OptionQuotes
  },
  {
    path: '/investor-acknowledgement/:id',
    name: 'InvestorAcknowledgement',
    component: InvestorAcknowledgement,
    props: true
  },
  {
    path: '/auth',
    name: 'AuthView',
    component: AuthView
  },
  {
    path: '/adviser-portal',
    name: 'AdviserPortal',
    component: AdviserPortal
  },
  {
    path: '/admin-portal',
    name: 'AdminPortal',
    component: AdminPortal
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router