import axios from 'axios';

const baseURL = process.env.VUE_APP_API_URL

function encodeURLWithParams(URL, params) {
    // Encode each key-value pair in the parameters
    const encodedParams = Object.keys(params).map(key => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
    }).join('&'); // Join all pairs with '&' to form the query string

    // Return the full URL with encoded parameters
    return `${URL}?${encodedParams}`;
}

const api = axios.create({
    baseURL: baseURL,
});

// Helper function to refresh the token
async function refreshToken() {
    try {
        const refresh_token = localStorage.getItem('refresh_token');
        const response = await axios.post(`${baseURL}/auth/refresh`, {
            refresh_token
        });
        localStorage.setItem('access_token', response.data.access_token);
        localStorage.setItem('refresh_token', response.data.refresh_token);
        return response.data.access_token;
    } catch (error) {
        console.error("Failed to refresh token:", error);
        throw error;
    }
}

// Request interceptor
api.interceptors.request.use(
    (config) => {
        // You can add common headers here, e.g., authentication tokens
        const access_token = localStorage.getItem('access_token');
        if (access_token) {
            config.headers['Authorization'] = `Bearer ${access_token}`;
            console.log('Access token added to request headers')
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response interceptor using a switch statement for error handling
api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        if (!error.response) {
            console.log('Error:', error.message)
            console.log('No response received');
            return Promise.reject(error);
        }

        switch (error.response.status) {
            case 401: // Unauthorized access
                if (!originalRequest._retry) {
                    originalRequest._retry = true; // Mark that we've retried
                    console.log("Refreshing token and retrying request")
                    try {
                        const new_access_token = await refreshToken();
                        originalRequest.headers['Authorization'] = `Bearer ${new_access_token}`;
                        return api(originalRequest);
                    } catch (refreshError) {
                        console.log('Redirecting to login due to token refresh failure');
                        return Promise.reject(refreshError); // Can handle redirection here or in higher component logic
                    }
                }
                break;
            case 404: // Not found
                console.log('Resource not found');
                break;
            default:
                console.log('An error occurred:', error.message);
        }
        return Promise.reject(error);
    }
);

export default api;
export { baseURL, encodeURLWithParams };