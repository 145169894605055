<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark px-3">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">Candour</a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav me-auto">
            <li v-for="item in menuItems" :key="item.text" class="nav-item" :class="{ 'dropdown': item.subItems }">
              <a v-if="!item.subItems" class="nav-link" :href="item.href">{{ item.text }}</a>
              <template v-else>
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {{ item.text }}
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li v-for="subItem in item.subItems" :key="subItem.text">
                    <a class="dropdown-item" :href="subItem.href">{{ subItem.text }}</a>
                  </li>
                </ul>
              </template>
            </li>
          </ul>
          <ul class="navbar-nav">
            <li v-if="!isLoggedIn" class="nav-item">
              <button @click="login" class="btn btn-outline-light">Login</button>
            </li>
            <li v-else class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="profileDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {{ userFirstName }}
                <!-- <img :src="user.profilePicture" alt="Profile" class="rounded-circle" width="32" height="32"> -->
              </a>
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="profileDropdown">
                <li v-for="item in profileDropdownItems">
                    <a v-if="item.href" class="dropdown-item" :href="item.href">{{ item.text }}</a>
                    <a v-else-if="item.action" class="dropdown-item" @click.prevent="item.action">{{ item.text }}</a>
                    <hr v-else class="dropdown-divider">
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </template>
  
<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const store = useStore();
const router = useRouter();

const isLoggedIn = computed(() => store.state.isLoggedIn);
const userFirstName = computed(() => localStorage.getItem('user_first_name'));

// Check login state when component mounts
onMounted(() => {
    store.dispatch('checkLoginState');
});

const user = ref({
    profilePicture: '/api/placeholder/32/32'
});

const login = () => {
    router.push('/auth');
};

const logout = () => {
    store.dispatch('logout');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    router.push('/');
};

const menuItems = ref([
    { text: 'Home', href: '/' },
    { text: 'Option', href: '/option-quotes' },
    { text: 'More', subItems: [
        { text: 'Team', href: '#team' },
        { text: 'Careers', href: '#careers' }
    ]}
]);

// Computed property to determine the first dropdown item based on user type
const portalItem = computed(() => {
    const userType = localStorage.getItem("user_type");
    if (userType === "investor") {
        return { text: 'Investor Portal', href: '/investor-portal' };
    } else if (userType === "adviser") {
        return { text: 'Adviser Portal', href: '/adviser-portal' };
    } else if (userType === "admin"){
        return { text: 'Admin Portal', href: '/admin-portal'};
    } else {
        return { text: 'Default Portal', href: '/investor-portal' }; // default case if needed
    }
});

// Static part of the dropdown items
const staticItems = [
    { text: 'Profile', href: '#profile' },
    { text: 'Settings', href: '#settings' },
    { text: 'Logout', action: logout }
];

// Full dropdown items array combining computed first item and static items
const profileDropdownItems = computed(() => [portalItem.value, ...staticItems]);
</script>