<template>
  <div class="autocomplete">
    <input
      :value="displayValue"
      @input="onInput"
      @focus="onFocus"
      @blur="onBlur"
      :placeholder="placeholder"
      :disabled="disabled"
    >
    <ul v-if="showSuggestions && filteredItems.length > 0" class="suggestions">
      <li
        v-for="item in filteredItems"
        :key="item"
        @mousedown="selectItem(item)"
        class="suggestion-item"
      >
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ref, computed, watch, nextTick } from 'vue';

const props = defineProps({
  modelValue: String,
  items: {
    type: Array,
    default: () => []
  },
  placeholder: String,
  disabled: Boolean,
  formatFunc: {
    type: Function,
    default: (value) => value
  }
});

const emit = defineEmits(['update:modelValue', 'select']);

const inputValue = ref(props.modelValue);
const showSuggestions = ref(false);

const displayValue = computed(() => props.formatFunc(inputValue.value));

const filteredItems = computed(() => {
  if (!props.items || props.items.length === 0) return [];
  return props.items.filter(item =>
    item.toLowerCase().includes(inputValue.value.toLowerCase())
  );
});

const onInput = (event) => {
  const newValue = event.target.value;
  const formattedValue = props.formatFunc(newValue);
  
  if (formattedValue !== newValue) {
    // If the formatted value is different, we need to update the input
    nextTick(() => {
      event.target.value = formattedValue;
      event.target.setSelectionRange(formattedValue.length, formattedValue.length);
    });
  }
  
  inputValue.value = formattedValue;
  emit('update:modelValue', formattedValue);
};

const onFocus = () => {
  if (props.items && props.items.length > 0) {
    showSuggestions.value = true;
  }
};

const selectItem = (item) => {
  inputValue.value = item;
  emit('update:modelValue', item);
  emit('select', item);
  showSuggestions.value = false;
};

const onBlur = () => {
  setTimeout(() => {
    showSuggestions.value = false;
  }, 200);
};

watch(() => props.modelValue, (newValue) => {
  inputValue.value = newValue;
});
</script>

<style scoped>
.autocomplete {
  position: relative;
  width: 100%;
  max-width: 300px;
  font-family: Arial, sans-serif;
}

.autocomplete-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  transition: border-color 0.3s ease;
}

.autocomplete-input:focus {
  border-color: #007bff;
}

.suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%; /* Ensure the suggestion list is the same width as the input */
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 0;
  margin: 0;
  list-style-type: none;
  box-sizing: border-box; /* Ensure padding is included in the width calculation */
}

.suggestion-item {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Add ellipsis for overflowing text */
}

.suggestion-item:hover {
  background-color: #f0f0f0;
}
</style>