import { createStore } from 'vuex';

export default createStore({
  state: {
    isLoggedIn: localStorage.getItem('isLoggedIn') === 'true'
  },
  mutations: {
    setLoginState(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn;
      localStorage.setItem('isLoggedIn', isLoggedIn);
    }
  },
  actions: {
    login({ commit }) {
      // Perform login logic here (e.g., API call)
      // If login is successful:
      commit('setLoginState', true);
    },
    logout({ commit }) {
      // Perform logout logic here (e.g., API call)
      commit('setLoginState', false);
    },
    checkLoginState({ commit }) {
      const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
      commit('setLoginState', isLoggedIn);
    }
  }
});