<template>
  <div>
    <button @click="openModal" class="btn btn-primary btn-lg">Click Here to Sign</button>
    
    <div v-if="isModalOpen" class="signature-modal">
      <div class="modal-content">
        <h2>Sign Here</h2>
        <div class="canvas-container">
          <canvas ref="signatureCanvas"></canvas>
        </div>
        <br>
        <div class="d-flex justify-content-center">
          <button @click="saveSignature" class="mx-1 btn btn-success">Save and Close</button>
          <button @click="clearSignature" class="mx-1 btn btn-danger">Clear</button>
          <button @click="closeModal" class="mx-1 btn btn-secondary">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, nextTick } from 'vue';
import SignaturePad from 'signature_pad';

const emit = defineEmits(['signature-saved']);

const isModalOpen = ref(false);
const signatureCanvas = ref(null);
let signaturePad;
const signatureData = ref(null);

const openModal = () => {
  isModalOpen.value = true;
  nextTick(() => {
    initSignaturePad();
  });
};

const initSignaturePad = () => {
  const canvas = signatureCanvas.value;
  signaturePad = new SignaturePad(canvas);

  // Set canvas size to match its display size
  resizeCanvas();

  // Restore previous signature if exists
  if (signatureData.value) {
    signaturePad.fromDataURL(signatureData.value);
  }
};

const resizeCanvas = () => {
  const canvas = signatureCanvas.value;
  const ratio = Math.max(window.devicePixelRatio || 1, 1);
  canvas.width = canvas.offsetWidth * ratio;
  canvas.height = canvas.offsetHeight * ratio;
  canvas.getContext("2d").scale(ratio, ratio);
  signaturePad.clear(); // Otherwise isEmpty() might return incorrect value
};

const closeModal = () => {
  // Save the current signature data before closing
  if (signaturePad && !signaturePad.isEmpty()) {
    signatureData.value = signaturePad.toDataURL();
  }
  isModalOpen.value = false;
};

const clearSignature = () => {
  signaturePad.clear();
  signatureData.value = null;
};

const saveSignature = () => {
  if (signaturePad.isEmpty()) {
    alert("Please provide a signature first.");
  } else {
    signatureData.value = signaturePad.toDataURL();
    emit("signature-saved", signatureData.value);
    closeModal();
  }
};

const handleOutsideClick = (event) => {
  if (event.target.classList.contains('signature-modal')) {
    closeModal();
  }
};

const handleResize = () => {
  if (isModalOpen.value) {
    resizeCanvas();
  }
};

onMounted(() => {
  document.addEventListener('click', handleOutsideClick);
  window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
  document.removeEventListener('click', handleOutsideClick);
  window.removeEventListener('resize', handleResize);
});
</script>

<style scoped>
.signature-modal {
  display: flex;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
}

.canvas-container {
  width: 100%;
  height: 200px;
}

canvas {
  border: 1px solid #000;
  width: 100%;
  height: 100%;
  touch-action: none;
}
</style>