<template>
  <div class="container-fluid mt-5" @click="handleOutsideClick">
    <div v-if="loading" class="text-center">
      <p>Loading orders...</p>
    </div>
    <div v-else-if="error" class="alert alert-danger" role="alert">
      {{ error }}
    </div>
    <div v-else-if="displayData.length === 0" class="text-center">
      <p>No orders available.</p>
    </div>
    <div v-else class="table-responsive">
      <table class="table table-hover table-bordered">
        <thead class="table-dark">
          <tr>
            <th v-for="header in headers" :key="header" scope="col">{{ header }}</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="row in tableData" :key="row.id">
            <tr 
              @click="toggleRow(row.id)"
              :class="{ 'table-active': activeRow === row.id }"
              class="clickable-row"
            >
              <td v-for="header in headers" :key="header">{{ row[header] }}</td>
            </tr>
            <tr v-if="activeRow === row.id" class="expanded-content">
              <td :colspan="headers.length">
                <div class="expanded-details p-3">
                  <div v-if="Object.keys(getHiddenDataForRow(row.id)).length > 0">
                    <div v-for="(value, key) in getHiddenDataForRow(row.id)" :key="key">
                      <strong>{{ key }}:</strong> {{ value }}
                    </div>
                  </div>
                  <div v-else>
                    No additional details available.
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <!-- <button @click="getAllOrders" class="btn btn-secondary" :disabled="loading">
      {{ loading ? 'Loading...' : 'Load Orders' }}
    </button> -->
  </div>
</template>
  
<script setup>
  import { ref, computed, onMounted } from 'vue';
  import api from '@/api';
  
  const displayData = ref([]);
  const hiddenData = ref({});
  const loading = ref(false);
  const error = ref(null);
  
  onMounted(() => {
    getAllOrders();
  });

  const getAllOrders = async () => {
    loading.value = true;
    error.value = null;
    try {
      const response = await api.get("/order/", {});
      displayData.value = response.data.display_data || [];
      hiddenData.value = response.data.hidden_data || {};
    } catch (err) {
      console.error("Error fetching orders:", err);
      error.value = "Failed to load orders. Please try again.";
    } finally {
      loading.value = false;
    }
  };
  
  const headers = computed(() => 
    displayData.value.length > 0 ? Object.keys(displayData.value[0]).filter(key => key !== 'id') : []
  );
  
  const tableData = computed(() => displayData.value);
  
  const activeRow = ref(null);
  
  const toggleRow = (rowId) => {
    activeRow.value = activeRow.value === rowId ? null : rowId;
  };
  
  const handleOutsideClick = (event) => {
    if (!event.target.closest('tr.clickable-row') && !event.target.closest('tr.expanded-content')) {
      activeRow.value = null;
    }
  };
  
  const getHiddenDataForRow = (rowId) => {
    return hiddenData.value[rowId] || {};
  };

  </script>
  
<style scoped>
  .clickable-row {
    cursor: pointer;
  }
  
  .expanded-content {
    background-color: #f8f9fa;
  }
  
  .expanded-details {
    animation: fadeIn 0.3s ease-in-out;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(-10px); }
    to { opacity: 1; transform: translateY(0); }
  }
  </style>