<template>
  <div class="p-3 m-1"
       :class="[
         'file-drop-zone', 
         'd-flex', 
         'justify-content-center', 
         'align-items-center', 
         'text-center',
         { 
           'is-dragover': isDragover && !fileUrl, 
           'has-file': fileUrl
         }
       ]" 
       @dragover.prevent="onDragover" 
       @dragleave="onDragleave" 
       @drop.prevent="onDrop"
       @click="triggerFileInput">
    <div v-if="fileUrl" class="d-flex flex-column align-items-center">
      <div class="file-icon position-relative" @dblclick="showFileViewer = true">
        <i v-if="fileType==='pdf'" class="fa-solid fa-file-pdf"></i>
        <i v-else-if="fileType==='word'" class="fa-solid fa-file-word"></i>
        <i v-else-if="fileType==='excel'" class="fa-solid fa-file-excel"></i>
        <i v-else-if="fileType==='powerpoint'" class="fa-solid fa-file-powerpoint"></i>
        <i v-else-if="fileType==='image'" class="fa-solid fa-file-image"></i>
        <i v-else class="fa-solid fa-file"></i>
        <span class="remove-icon position-absolute" @click.stop="confirmRemove">×</span>
      </div>
      <span class="file-name">{{ fileName }}</span>
    </div>
    <div v-else class="drop-zone">
      <i class="fas fa-cloud-upload-alt fa-3x"></i>
      <p class="mt-2">Drag and drop a file here <br/>or click to upload</p>
    </div>
    <input type="file" @change="onFileSelected" ref="fileInput" class="d-none">
  </div>

  <!-- FileViewer Pop-up -->
  <div v-if="showFileViewer" class="file-viewer-popup d-flex justify-content-center align-items-center">
    <div class="file-viewer-content d-flex justify-content-center align-items-center">
      <button @click="showFileViewer = false" class="close-button">×</button>
      <FileViewer 
      style="width: 100%"
      :source="fileUrl" />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import FileViewer from '@/components/FileViewer.vue';
import  { detectFileType } from '@/utils/fileTypeDetector.js'

const props = defineProps({
  resourceUrl: String,
})

const emit = defineEmits(['update:file', 'update:url'])

const fileUrl = ref(props.resourceUrl || '')
const fileType = ref('')
const fileName = ref('')
const isDragover = ref(false)
const fileInput = ref(null)
const showFileViewer = ref(false)

const updateFileType = async () => {
  if (fileUrl.value) {
    fileType.value = await detectFileType(fileUrl.value)
  } else {
    fileType.value = ''
  }
}

const onDragover = (e) => {
  if (!fileUrl.value) {
    isDragover.value = true
    e.preventDefault()
  }
}

const onDragleave = () => isDragover.value = false

const onDrop = (e) => {
  if (!fileUrl.value) {
    isDragover.value = false
    const files = e.dataTransfer.files
    if (files.length) handleFile(files[0])
  }
}

const triggerFileInput = () => {
  if (!fileUrl.value && fileInput.value) {
    fileInput.value.click()
  }
}

const onFileSelected = (e) => {
  const files = e.target.files
  if (files.length) handleFile(files[0])
}

const handleFile = (file) => {
  fileName.value = file.name
  const reader = new FileReader()
  reader.onload = async (e) => {
    fileUrl.value = e.target.result
    emit('update:file', file)
    emit('update:url', '')
    console.log('File uploaded:', file)
    await updateFileType()
  }
  reader.readAsDataURL(file)
}

const confirmRemove = (e) => {
  e.stopPropagation()
  if (confirm('Are you sure you want to remove this file?')) {
    fileUrl.value = ''
    fileName.value = ''
    emit('update:file', null)
    emit('update:url', '')
  }
}

watch(() => props.resourceUrl, async (newUrl) => {
  if (newUrl && newUrl !== fileUrl.value) {
    fileUrl.value = newUrl
    fileName.value = newUrl.split('/').pop()
    emit('update:url', newUrl)
    emit('update:file', null)
    await updateFileType()
  }
})

onMounted(async () => {
  if (props.resourceUrl) {
    fileUrl.value = props.resourceUrl
    fileName.value = props.resourceUrl.split('/').pop()
    await updateFileType()
  }
})



</script>

<style scoped>
.file-drop-zone {
  width: 100%;
  height: 100%;
  border: 2px dashed #ccc;
  border-radius: 8px;
}

.file-drop-zone:not(.has-file) {
  cursor: pointer;
}

.file-drop-zone.has-file {
  border: none;
}

.is-dragover {
  background-color: #f0f0f0;
  border-color: #999;
}

.file-icon {
  font-size: 48px;
  cursor: pointer;
}

.remove-icon {
  top: -5px;
  right: -10px;
  color: black;
  width: 20px;
  height: 20px;
  font-size: 20px;
  line-height: 1;
}

.remove-icon:hover {
  color: red;
  font-size: 22px;
  cursor: pointer;
}

.file-name {
  word-break: break-all;
}

.file-viewer-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.file-viewer-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  width: 80%;
  height: 80%;
  overflow: auto;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1001;
}
</style>