<template>
  <div>
    <div class="card m-4 product-card" @click="showModal = true">
      <img :src="product.image" :alt="product.name" class="card-img-top">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title">Product Type: {{ product.product_type }}</h5>
        <h5 class="card-title" v-if="product.name">Product Name: {{ product.name }}</h5>
        <p class="card-text flex-grow-1" v-if="product.description">{{ product.description }}</p>
      </div>
    </div>

    <!-- Edit Modal -->
    <div v-if="showModal" class="modal-backdrop fade show"></div>
    <div v-if="showModal" class="modal fade show d-block" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Product</h5>
            <button type="button" class="btn-close" @click="showModal = false" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form>
              <div class="mb-3">
                <label for="productType" class="form-label">Product Type</label>
                <input type="text" class="form-control" id="productType" v-model="product.product_type">
              </div>
              <div class="mb-3">
                <label for="productName" class="form-label">Product Name</label>
                <input type="text" class="form-control" id="productName" v-model="product.name">
              </div>
              <div class="mb-3">
                <label for="productDescription" class="form-label">Description</label>
                <textarea class="form-control" id="productDescription" v-model="product.description"></textarea>
              </div>
            </form>

            <!-- Tabbed Window -->
            <div class="mt-4">
              <ul class="nav nav-tabs">
                <li class="nav-item" v-for="(content, tabName) in productFiles" :key="tabName">
                  <a class="nav-link" :class="{ active: activeTab === tabName }" @click.prevent="activeTab = tabName" href="#">
                    {{ tabName }}
                  </a>
                </li>
              </ul>
              <div class="tab-content mt-2">
                <div v-for="(content, tabName) in productFiles" :key="tabName" class="tab-pane fade" :class="{ 'show active': activeTab === tabName }">
                  <!-- Use v-show instead of v-if -->
                  <div v-show="activeTab === tabName" class="border p-3 d-flex justify-content-center align-items-center">
                    <DropZone :resourceUrl="content" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="showModal = false">Close</button>
            <button type="button" class="btn btn-primary" @click="saveChanges">Save changes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps } from 'vue'
import DropZone from '@/components/DropZone.vue'

const props = defineProps({
  id: {
    type: String,
    required: true
  }
})

const product = ref({
  product_type: 'Bond',
  name: 'ANZ 6.126% 2023',
  description: 'ANZ bond maturing in 2023, paying 6.126% p.a.',
  image: 'https://via.placeholder.com/200', // Placeholder image URL
})

const showModal = ref(false)
const activeTab = ref('Term Sheet')

const productFiles = {
  'Term Sheet': '',
  'Flyer': '',
  'Presentation': '',
  'Fact Sheet': '',
  'Pricing Supplement': ''
}

const saveChanges = () => {
  // Here you would typically send the updated product data to your backend
  console.log('Saving changes:', product.value)
  showModal.value = false
}
</script>

<style scoped>
.product-card {
  width: 300px;
  height: 300px;
  cursor: pointer;
}

.product-card img {
  height: 150px;
  object-fit: cover;
}

.modal-backdrop {
  z-index: 1040;
}

.modal {
  z-index: 1050;
}

body.modal-open {
  overflow: hidden;
}

.modal-xl {
  max-width: 90%;
  width: 90%;
}
</style>