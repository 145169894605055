<template>
  <div class="home p-4">
    <h1>Home Page</h1>
    <!-- <router-link to="/option-quotes">Go to Option Quotes</router-link> -->
    <!-- <br/> -->
    <!-- <router-link to="/investor-acknowledgement">Go to Acknowledgement</router-link> -->
    <div style="height:500px; width:400px;">
      <DropZone resourceUrl=""/>
    </div>
    <!-- <DropZone resource-url="https://www.lakemills.k12.wi.us/cms_files/resources/Downloading%20Documents.doc"/> -->
  </div>
</template>


<script setup>
import { ref } from 'vue'
import FileViewer from '@/components/FileViewer.vue'
import VuePdfEmbed from 'vue-pdf-embed'
// optional styles
import 'vue-pdf-embed/dist/style/index.css'
import DropZone from '@/components/DropZone.vue'

const currentMode = ref('edit')

const toggleMode = () => {
  currentMode.value = currentMode.value === 'edit' ? 'view' : 'edit'
}


// either URL, Base64, binary, or document proxy
const pdfSource = 'https://s2.q4cdn.com/470004039/files/doc_earnings/2023/q4/filing/_10-K-Q4-2023-As-Filed.pdf'
</script>

<style>
/* make the pdf window scrollable if the pdf is too big */
#pdfWindow {
  overflow-y: auto;
  height: 500px;
  width: 100%;
  border: 1px solid #ccc;
  margin-top: 20px;
  padding: 10px;
  box-sizing: border-box;
}
</style>