<template>
    <div class="container my-5">
      <div class="row justify-content-center">
        <div class="card mx-2">
          <div class="card-body">
            <!-- Login Form -->
            <form v-if="currentForm === 'login'" @submit.prevent="submitLoginForm" class="p-3">
              <h2 class="mb-4 text-center card-title">Login</h2>
              <div class="mb-3">
                <input type="email" v-model="loginEmail" class="form-control" placeholder="Email" required>
              </div>
              <div class="mb-3">
                <input type="password" v-model="loginPassword" class="form-control" placeholder="Password" required>
              </div>
              <button type="submit" class="btn btn-primary w-100">Login</button>
            </form>

            <!-- Sign Up Form -->
            <form v-if="currentForm === 'signup'" @submit.prevent="submitSignupForm" class="p-3">
              <h2 class="mb-4 text-center">Sign Up</h2>
              <div class="mb-3">
                <input type="email" v-model="signupEmail" class="form-control" placeholder="Email" required>
              </div>
              <div class="mb-3">
                <input type="password" v-model="signupPassword" class="form-control" placeholder="Password" required>
              </div>
              <div class="mb-3">
                <input type="text" v-model="firstName" class="form-control" placeholder="First Name" required>
              </div>
              <div class="mb-3">
                <input type="text" v-model="lastName" class="form-control" placeholder="Last Name" required>
              </div>
              <button type="submit" class="btn btn-primary w-100">Sign Up</button>
            </form>

            <!-- Forgot Password Form -->
            <form v-if="currentForm === 'forgotPassword'" @submit.prevent="submitForgetPasswordForm" class="p-3">
              <h2 class="mb-4 text-center">Forgot Password</h2>
              <div class="mb-3">
                <input type="email" v-model="forgotPasswordEmail" class="form-control" placeholder="Email" required>
              </div>
              <button type="submit" class="btn btn-primary w-100">Reset Password</button>
            </form>

            <div class="mt-1 text-center">
              <a href="#" @click.prevent="currentForm = 'signup'" v-if="currentForm === 'login'" class="me-2 text-secondary mx-3">Sign Up</a>
              <a href="#" @click.prevent="currentForm = 'forgotPassword'" v-if="currentForm === 'login'" class="text-secondary mx-3">Forgot Password?</a>
              <a href="#" @click.prevent="currentForm = 'login'" v-if="currentForm !== 'login'" class="text-secondary mx-3">Back to Login</a>
            </div>

            <div v-if="message" :class="['alert', 'mt-3', isError ? 'alert-danger' : 'alert-success']" role="alert">
              {{ message }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, computed } from 'vue';
  import { useStore } from 'vuex';
  import api from '@/api';
  
  const store = useStore();

  const currentForm = ref('login');
  const message = ref('');
  const isError = ref(false);
  const isLoggedIn = computed(() => store.state.isLoggedIn);
  
  // Form data
  const loginEmail = ref('');
  const loginPassword = ref('');
  const signupEmail = ref('');
  const signupPassword = ref('');
  const firstName = ref('');
  const lastName = ref('');
  const forgotPasswordEmail = ref('');
  
  const showMessage = (msg, error = false) => {
    message.value = msg;
    isError.value = error;
  };
  
  const submitLoginForm = async () => {
    try {
      const response = await api.post('/auth/login', {
        email: loginEmail.value,
        password: loginPassword.value
      });
      showMessage('Login successful!');
      localStorage.setItem('access_token', response.data.access_token);
      localStorage.setItem('refresh_token', response.data.refresh_token);
      localStorage.setItem('user_first_name', response.data.user_first_name);
      localStorage.setItem('user_type', response.data.user_type);
      store.dispatch('login');
    } catch (error) {
      showMessage(error.response?.data?.detail || 'An error occurred', true);
    }
  };
  
  const submitSignupForm = async () => {
    try {
      const response = await api.post('/auth/signup', {
        email: signupEmail.value,
        password: signupPassword.value,
        first_name: firstName.value,
        last_name: lastName.value
      });
      showMessage('Sign up successful! Please log in.');
      currentForm.value = 'login';
      localStorage.setItem('access_token', response.data.access_token);
      localStorage.setItem('refresh_token', response.data.refresh_token);
      store.dispatch('login');
    } catch (error) {
      showMessage(error.response?.data?.detail || 'An error occurred', true);
    }
  };
  
  const submitForgetPasswordForm = async () => {
    try {
      await api.post('/auth/forgot-password', {
        email: forgotPasswordEmail.value
      });
      showMessage('Password reset instructions sent to your email.');
      currentForm.value = 'login';
    } catch (error) {
      showMessage(error.response?.data?.detail || 'An error occurred', true);
    }
  };
    const test = async () => {
      await api.get('/auth/something');
      console.log("test")
    }
  </script>

  <style>
  .card {
    max-width: 600px;
  }
  </style>