<template>
  <div class="file-viewer" ref="containerRef">
    <template v-if="fileType === 'pdf'">
      <VuePdfEmbed
        :source="source"
        :width="viewerWidth"
        :height="viewerHeight"
      />
    </template>
    <template v-else-if="fileType === 'docx'">
      <iframe
        :src="`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(source)}`"
        :width="viewerWidth"
        :height="viewerHeight"
        frameborder="0"
      ></iframe>
    </template>
    <template v-else-if="fileType === 'image'">
      <div class="image-container" :style="{ width: viewerWidth + 'px', height: viewerHeight + 'px' }">
        <img
          :src="source"
          :style="imageStyle"
          alt="Image preview"
        />
      </div>
    </template>
    <template v-else>
      <p>Unsupported file type or still detecting...</p>
    </template>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import VuePdfEmbed from 'vue-pdf-embed';
import 'vue-pdf-embed/dist/style/index.css';

const props = defineProps({
  source: {
    type: String,
    required: true
  }
});

const containerRef = ref(null);
const viewerWidth = ref(0);
const viewerHeight = ref(0);
const detectedFileType = ref('unknown');
const imageNaturalWidth = ref(0);
const imageNaturalHeight = ref(0);

const fileType = computed(() => {
  const urlFileType = getFileTypeFromUrl(props.source);
  return urlFileType !== 'unknown' ? urlFileType : detectedFileType.value;
});

const imageStyle = computed(() => {
  if (fileType.value !== 'image') return {};

  const containerAspectRatio = viewerWidth.value / viewerHeight.value;
  const imageAspectRatio = imageNaturalWidth.value / imageNaturalHeight.value;

  if (imageAspectRatio > containerAspectRatio) {
    // Image is wider than the container
    return {
      width: '100%',
      height: 'auto',
      objectFit: 'contain'
    };
  } else {
    // Image is taller than the container
    return {
      width: 'auto',
      height: '100%',
      objectFit: 'contain'
    };
  }
});

const getFileTypeFromUrl = (url) => {
  const extension = url.split('.').pop().toLowerCase();
  if (extension === 'pdf') return 'pdf';
  if (['doc', 'docx'].includes(extension)) return 'docx';
  if (['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(extension)) return 'image';
  return 'unknown';
};

const detectFileType = async () => {
  try {
    const response = await fetch(props.source, { method: 'HEAD' });
    const contentType = response.headers.get('Content-Type');
    
    if (contentType.includes('application/pdf')) {
      detectedFileType.value = 'pdf';
    } else if (contentType.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
               contentType.includes('application/msword')) {
      detectedFileType.value = 'docx';
    } else if (contentType.startsWith('image/')) {
      detectedFileType.value = 'image';
    } else {
      detectedFileType.value = 'unknown';
    }
  } catch (error) {
    console.error('Error detecting file type:', error);
    detectedFileType.value = 'unknown';
  }
};

const loadImage = () => {
  if (fileType.value === 'image') {
    const img = new Image();
    img.onload = () => {
      imageNaturalWidth.value = img.naturalWidth;
      imageNaturalHeight.value = img.naturalHeight;
    };
    img.src = props.source;
  }
};

const updateContainerSize = () => {
  if (containerRef.value) {
    viewerWidth.value = containerRef.value.clientWidth;
    viewerHeight.value = containerRef.value.clientHeight;
  }
};

onMounted(() => {
  updateContainerSize();
  window.addEventListener('resize', updateContainerSize);
  detectFileType();
  loadImage();
});

onUnmounted(() => {
  window.removeEventListener('resize', updateContainerSize);
});
</script>

<style scoped>
.file-viewer {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.image-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
</style>